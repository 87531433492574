// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";

import SentryRRWeb from "@sentry/rrweb";
// If taking advantage of automatic instrumentation (highly recommended)
import { Integrations as TracingIntegrations } from "@sentry/tracing";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
const ENV = process.env.NEXT_PUBLIC_DEV_ENV;

Sentry.init({
  environment: ENV,
  dsn:
    SENTRY_DSN ||
    "https://1ea69e81a75b40c786fc2835d337fca3@o685178.ingest.sentry.io/5772163",
  maxBreadcrumbs: 50,
  integrations: [
    new TracingIntegrations.BrowserTracing({
      tracingOrigins: ["localhost", "memecoingen.com", /^\//],
    }),
    new SentryRRWeb({
      maskAllInputs: false,
    }),
  ],
  tracesSampleRate: 1.0,
  debug: ENV === "development" || ENV === "preview" ? true : false,
  release: process.env.npm_package_version,
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps

  beforeSend(event, hint) {
    const error = hint.originalException;
    if (
      error &&
      error.message &&
      error.message.match(/Modal closed by user/i)
    ) {
      return null;
    }

    return event;
  },
});
